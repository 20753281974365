import { applySnapshot, types } from 'mobx-state-tree';

const Flags = types
  .model({
    isAuthenticated: types.optional(types.boolean, false),
    isFetchingLogin: false,
    isFetchingLoginError: false,
  })
  .actions((self) => ({
    updateFlag: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }));

export default Flags;
