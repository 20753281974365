import { FC } from 'react';
import DefaultDrawer from '@/components/DefaultDrawer';
import { IDrawerContainerProps } from './types';

const ComponentHandler: FC<IDrawerContainerProps> = ({ component, onClose }) => {
  const {
    options: { useCustomDrawer, ...options },
    props,
    component: Component,
  } = component;

  return useCustomDrawer ? (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Component {...props} />
  ) : (
    <DefaultDrawer options={options} onClose={onClose}>
      {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
      {/*@ts-ignore*/}
      <Component {...props} />
    </DefaultDrawer>
  );
};

export default ComponentHandler;
