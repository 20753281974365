import clsx from 'clsx';
import { FC } from 'react';

import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { IDrawerComponentProps } from '@/components/DrawerWrapper/types';
import useStyles from './styles';

const DrawerComponent: FC<IDrawerComponentProps> = ({ onClose, options, children }) => {
  const css = useStyles();
  const { showCloseButton, title, subtitle, anchor } = options;

  const CloseButton = () => (
    <IconButton
      data-testid="close-btn"
      size="small"
      aria-label="close"
      className={css.icon}
      onClick={onClose}
    >
      {anchor === 'right' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
    </IconButton>
  );

  const headerCls = clsx(css.header, { [css.headerReverse]: anchor === 'left' });
  const subtitleContainerCls = clsx(css.subtitleContainer, {
    [css.subtitleContainerReverse]: anchor === 'left',
  });

  return (
    <>
      <div className={headerCls} data-testid="header">
        <div className={subtitleContainerCls}>
          {showCloseButton && <CloseButton />}
          <span data-testid="subtitle" className={css.subtitle}>
            {subtitle}
          </span>
        </div>
        <h2 className={css.title} data-testid="title">
          {title}
        </h2>
      </div>
      {children}
    </>
  );
};

export default DrawerComponent;
