import { FC } from 'react';
import { Redirect } from 'react-router-dom';

export interface RedirectToProps {
  pathName?: string;
}

const RedirectTo: FC<RedirectToProps> = ({ pathName = '/' }) => {
  return <Redirect from="*" to={{ pathname: pathName }} />;
};

export default RedirectTo;
