import { types, applySnapshot, getSnapshot, Instance, flow, cast } from 'mobx-state-tree';
import axios from 'axios';
import debug from 'debug';

import endpoints from '@/config/endpoints';

import Flags from './models/Flags';
import MediaFiles from './models/MediaFiles';
import Pagination from './models/Pagination';

const log = debug('store:admin:log');
const errorLog = debug('store:admin:error');

const AdminStore = types
  .model({
    mediaFilesList: types.optional(types.array(MediaFiles), []),
    flags: types.optional(Flags, {}),
    pagination: types.optional(Pagination, {}),
    actualPage: 1,
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
      delay: (ms: number) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    };
  })
  .actions((self) => ({
    getMediaFilesList: flow(function* () {
      log('getMediaFilesList >>>>');
      try {
        self.flags.isFetchingMediaFilesList = true;
        const response = yield axios.get(endpoints.getMediaFilesList);
        log('getMediaFilesListResponse >>>>', response);
        if (response.data.success) {
          self.mediaFilesList = cast(response.data.mediaFiles);
          // self.pagination = cast(response.data.pagination);
        }
      } catch (err) {
        errorLog('getMediaFilesListError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingMediaFilesList = false;
      }
    }),
    approveMediaFile: flow(function* (data) {
      log('approveMediaFile >>>>', data);
      try {
        self.flags.isFetchingMediaFilesList = true;
        const response = yield axios.patch(endpoints.approveMediaFile, data);
        log('approveMediaFileResponse >>>>', response);
        /* if (response.data.success) {
        } */
      } catch (err) {
        errorLog('approveMediaFileError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingMediaFilesList = false;
      }
    }),
    rejectMediaFile: flow(function* (data) {
      log('rejectMediaFile >>>>', data);
      try {
        self.flags.isFetchingRejectingMediaFile = true;
        const response = yield axios.patch(endpoints.rejectMediaFile, data);
        log('rejectMediaFileResponse >>>>', response);
        /* if (response.data.success) {
        } */
      } catch (err) {
        errorLog('rejectMediaFileError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingRejectingMediaFile = false;
      }
    }),
  }))
  .actions((self) => ({}));

export interface IAdminStore extends Instance<typeof AdminStore> {}

export default AdminStore;
