import { Instance, types } from 'mobx-state-tree';

const MediaFile = types.model({
  id: types.maybeNull(types.number),
  mediaFileId: types.maybeNull(types.number),
  artistId: types.maybeNull(types.number),
  mediaId: types.maybeNull(types.number),
  genderId: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  artistName: types.maybeNull(types.string),
  mediaTitle: types.maybeNull(types.string),
  mediaType: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  filePath: types.maybeNull(types.string),
  artworkPath: types.maybeNull(types.string),
  totalPlays: types.maybeNull(types.number),
  isFavorite: types.maybeNull(types.boolean),
  totalComments: types.maybeNull(types.number),
  rightsReserved: types.maybeNull(types.string),
  compositionType: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  pathType: types.maybeNull(types.string),
  valid: types.maybeNull(types.number),
});

export interface IMediaFile extends Instance<typeof MediaFile> {}
export default MediaFile;
