import { Instance, types } from 'mobx-state-tree';

const Artist = types.model({
  userId: types.maybeNull(types.number),
  artistId: types.maybeNull(types.number),
  genderId: types.maybeNull(types.number),
  artist: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  profilePicture: types.maybeNull(types.string),
  profileLandPicture: types.maybeNull(types.string),
  biography: types.maybeNull(types.string),
  followers: types.maybeNull(types.number),
  active: types.optional(types.number, 0),
  pathType: types.maybeNull(types.string),
});

export interface IArtist extends Instance<typeof Artist> {}
export default Artist;
