export const HASHES = ['#media', '#user-data', '#admin-library'];

export const ROUTES = {
  MY_CONTENT: '/my-content',
  LOGIN: '/login',
  REGISTER: '/register',
  PROFILE: '/profile',
  CHANGE_PASSWORD: '/change-password',
  ADMINISTRATION: '/administration',
};
