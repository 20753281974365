enum allowedRoles {
  ADMIN = 'admin',
  USER = 'user',
}

enum allowedRolesDisplayEs {
  admin = 'Administrador',
  user = 'Usuario',
}

export { allowedRoles, allowedRolesDisplayEs };
