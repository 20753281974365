import React, { createContext, FC, ReactNode, useMemo } from 'react';

export const PermissionsContext = createContext({
  accessActions: new Set<string>(),
  accessRole: '',
});

export interface PermissionsProviderProps {
  accessActions: string[];
  accessRole: string;
  children: ReactNode;
}

export const PermissionsProvider: FC<PermissionsProviderProps> = ({
  accessActions,
  accessRole,
  children,
}) => {
  const value = useMemo(() => {
    return {
      accessActions: new Set(accessActions),
      accessRole,
    };
  }, [accessActions, accessRole]);
  return <PermissionsContext.Provider value={value}>{children}</PermissionsContext.Provider>;
};
