import { Instance, types } from 'mobx-state-tree';
import MediaFile from './MediaFiles';

const Media = types.model({
  mediaId: types.maybeNull(types.number),
  artistId: types.maybeNull(types.number),
  genderId: types.maybeNull(types.number),
  title: types.maybeNull(types.string),
  type: types.maybeNull(types.string),
  artistName: types.maybeNull(types.string),
  gender: types.maybeNull(types.string),
  artworkPath: types.maybeNull(types.string),
  totalComments: types.maybeNull(types.number),
  mediaFiles: types.optional(types.array(MediaFile), []),
  pathType: types.maybeNull(types.string),
});

export interface IMedia extends Instance<typeof Media> {}
export default Media;
