import { types, applySnapshot, getSnapshot, Instance, flow, getRoot, cast } from 'mobx-state-tree';
import axios from 'axios';
import debug from 'debug';

import endpoints from '@/config/endpoints';
import { IGlobalStore } from '@/store';

import Flags from './models/Flags';
import Media from './models/Media';

const log = debug('store:media:log');
const errorLog = debug('store:media:error');

const MyContentStore = types
  .model({
    mediaList: types.optional(types.array(Media), []),
    media: types.optional(Media, { mediaFiles: [] }),
    flags: types.optional(Flags, {}),
  })
  .views((self) => ({
    get mediaFilesLength() {
      return self?.media?.mediaFiles ? self.media.mediaFiles.length : 0;
    },
  }))
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
      delay: (ms: number) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    };
  })
  .actions((self) => ({
    getMyMediaList: flow(function* ({ artistId }) {
      log('getMyMediaList >>>>', artistId);
      try {
        self.flags.isFetchingMyMediaList = true;
        const response = yield axios.get(endpoints.getMyMediaList(artistId));
        log('getMyMediaListResponse >>>>', response);
        if (response.data.success) {
          self.mediaList = cast(response.data.media);
        }
      } catch (err) {
        errorLog('getMyMediaListError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingMyMediaList = false;
      }
    }),
    getMediaInfo: flow(function* ({ mediaId }) {
      log('getMediaInfo >>>>', mediaId);
      try {
        self.flags.isFetchingMediaInfo = true;
        const response = yield axios.get(endpoints.getMediaInfo(mediaId));
        log('getMediaInfoResponse >>>>', response);
        if (response.data.success) {
          self.media = cast(response.data.media);
        }
      } catch (err) {
        errorLog('getMediaInfoError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingMediaInfo = false;
      }
    }),
  }))
  .actions((self) => ({
    createMedia: flow(function* (data) {
      const { userStore } = getRoot<IGlobalStore>(self);
      log('createMedia >>>>', data);
      try {
        self.flags.isFetchingCreatingMedia = true;
        const response = yield axios.post(endpoints.createMedia, data);
        log('createMediaResponse >>>>', response);
        if (response.data.success) {
          yield self.getMyMediaList({ artistId: userStore.userData.artistId });
        }
      } catch (err) {
        errorLog('createMediaError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingCreatingMedia = false;
      }
    }),
    createMediaFile: flow(function* (data) {
      log('createMediaFile >>>>', data);
      try {
        self.flags.isFetchingCreatingMediaFile = true;
        const response = yield axios.post(endpoints.createMediaFile, data);
        log('createMediaFileResponse >>>>', response);
        if (response.data.success) {
          yield self.getMediaInfo({ mediaId: data.mediaId });
        }
      } catch (err) {
        errorLog('createMediaError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingCreatingMediaFile = false;
      }
    }),
    updateMedia: flow(function* (data) {
      const { userStore } = getRoot<IGlobalStore>(self);
      log('updateMedia >>>>', data);
      try {
        self.flags.isFetchingUpdatingMedia = true;
        const response = yield axios.patch(endpoints.updateMedia, data);
        log('updateMediaResponse >>>>', response);
        if (response.data.success) {
          yield self.getMyMediaList({ artistId: userStore.userData.artistId });
          yield self.getMediaInfo({ mediaId: data.mediaId });
        }
      } catch (err) {
        errorLog('updateMediaError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdatingMedia = false;
      }
    }),
    deleteMedia: flow(function* ({ mediaId }) {
      const { userStore } = getRoot<IGlobalStore>(self);
      log('deleteMedia >>>>', mediaId);
      try {
        self.flags.isFetchingDeletingMedia = true;
        const response = yield axios.delete(endpoints.deleteMedia(mediaId));
        log('deleteMediaResponse >>>>', response);
        if (response.data.success) {
          yield self.getMediaInfo({ mediaId });
          yield self.getMyMediaList({ artistId: userStore.userData.artistId });
        }
      } catch (err) {
        errorLog('deleteMediaError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingDeletingMedia = false;
      }
    }),
    updateMediaFile: flow(function* (data) {
      log('updateMediaFile >>>>', data);
      try {
        self.flags.isFetchingUpdatingMediaFile = true;
        const response = yield axios.patch(endpoints.updateMediaFile, data);
        log('updateMediaFileResponse >>>>', response);
        if (response.data.success) {
          yield self.getMediaInfo({ mediaId: data.mediaId });
        }
      } catch (err) {
        errorLog('updateMediaFileError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdatingMediaFile = false;
      }
    }),
    deleteMediaFile: flow(function* ({ mediaFileId, mediaId }) {
      log('deleteMediaFile >>>>', mediaFileId);
      try {
        self.flags.isFetchingDeletingMediaFile = true;
        const response = yield axios.delete(endpoints.deleteMediaFile(mediaFileId));
        log('deleteMediaFileResponse >>>>', response);
        if (response.data.success) {
          yield self.getMediaInfo({ mediaId });
        }
      } catch (err) {
        errorLog('deleteMediaFileError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingDeletingMediaFile = false;
      }
    }),
  }));

export interface IMyContentStore extends Instance<typeof MyContentStore> {}

export default MyContentStore;
