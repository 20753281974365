import { useEffect } from 'react';
import useStore from '@/hooks/useStore';

function useResizeUi(): void {
  const { uiStore } = useStore();

  useEffect(() => {
    uiStore.detectDeviceType();

    const handleResize = () => {
      uiStore.updateField('width', window.innerWidth);
      uiStore.updateField('height', window.innerHeight);
      if (window.innerWidth <= 640 && !uiStore.isMobileScreen) {
        uiStore.updateField('isMobileScreen', true);
      } else if (window.innerWidth > 640 && uiStore.isMobileScreen) {
        uiStore.updateField('isMobileScreen', false);
      }
      if (window.innerHeight <= 500) {
        uiStore.setIsKeyboardPopped(true);
      } else {
        uiStore.setIsKeyboardPopped(false);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
}

export default useResizeUi;
