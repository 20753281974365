import { useEffect } from 'react';
import { env } from '@/config/variables';

function useDebugger() {
  useEffect(() => {
    if (env !== 'prod') {
      localStorage.setItem('debug', '*');
    } else {
      localStorage.removeItem('debug');
    }
  }, []);
}

export default useDebugger;
