import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import useStore from '../useStore';
import { ROUTES } from '@/constants/routes';

function useLogout() {
  const { loginStore } = useStore();
  const history = useHistory();

  const logoutUser = useCallback(async () => {
    try {
      await loginStore.logoutReset();
    } finally {
      history.push(ROUTES.LOGIN);
    }
  }, []);

  return { logoutUser };
}

export default useLogout;
