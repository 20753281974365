import React, { FC } from 'react';
import { observer } from 'mobx-react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AccessRoute from '@/components/AccessRoute';
import UnAuthRoute from '@/components/UnAuthRoute';
import AuthRoute from '@/components/AuthRoute';

import { allowedActions } from '@/libraries/accessManager/constants/allowedActions';
import { ROUTES } from '@/constants/routes';

import Login from '@/pages/Login';
import Register from '@/pages/Register';
import MyContent from '@/pages/MyContent';
import Profile from '@/pages/Profile';
import ChangePassword from '@/pages/ChangePassword';
import Administration from '@/pages/Administration';

const Router: FC = () => {
  return (
    <Switch>
      <Route path={ROUTES.LOGIN}>
        <UnAuthRoute>
          <Login />
        </UnAuthRoute>
      </Route>
      <Route path={ROUTES.REGISTER}>
        <UnAuthRoute>
          <Register />
        </UnAuthRoute>
      </Route>
      <Route path={ROUTES.CHANGE_PASSWORD}>
        <UnAuthRoute>
          <ChangePassword />
        </UnAuthRoute>
      </Route>
      <Route path={ROUTES.MY_CONTENT}>
        <AuthRoute>
          <AccessRoute allowedAction={allowedActions.MY_CONTENT_ACCESS}>
            <MyContent />
          </AccessRoute>
        </AuthRoute>
      </Route>
      <Route path={ROUTES.ADMINISTRATION}>
        <AuthRoute>
          <AccessRoute allowedAction={allowedActions.MY_CONTENT_ACCESS}>
            <Administration />
          </AccessRoute>
        </AuthRoute>
      </Route>
      <Route path={ROUTES.PROFILE}>
        <AuthRoute>
          <AccessRoute allowedAction={allowedActions.PROFILE_ACCESS}>
            <Profile />
          </AccessRoute>
        </AuthRoute>
      </Route>
      <Redirect from="*" to={ROUTES.LOGIN} />
    </Switch>
  );
};

export default observer(Router);
