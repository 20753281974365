import { types, applySnapshot, getSnapshot, Instance, flow, getRoot, cast } from 'mobx-state-tree';
import axios from 'axios';
import debug from 'debug';

import endpoints from '@/config/endpoints';
import { IGlobalStore } from '@/store';

import Flags from './models/Flags';
import User from './models/User';

const log = debug('store:user:log');
const errorLog = debug('store:user:error');

const UserStore = types
  .model({
    userId: types.maybeNull(types.string),
    userData: types.optional(User, {}),
    flags: types.optional(Flags, {}),
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
      delay: (ms: number) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    };
  })
  .actions((self) => ({
    getUserData: flow(function* ({ silent = false }: any) {
      const { loginStore } = getRoot<IGlobalStore>(self);
      log('getUserData >>>>');
      try {
        if (!silent) {
          self.flags.isFetchingUserData = true;
        }
        const response = yield axios.get(endpoints.getUser);
        log('getUserDataResponse >>>>', response);
        if (response.data.success) {
          loginStore.flags.updateFlag('isAuthenticated', true);
          self.userData = cast(response.data.userData);
        }
      } catch (err) {
        errorLog('getUserDataError >>>>', err);
        loginStore.resetStore();
      }
    }),
  }))
  .actions((self) => ({
    createAccount: flow(function* (data) {
      const { loginStore } = getRoot<IGlobalStore>(self);
      log('createAccount >>>>', data);
      try {
        self.flags.isFetchingRegisterUser = true;
        const response = yield axios.post(endpoints.createAccount, data);
        log('createAccountResponse >>>>', response);
        if (response.data.success) {
          loginStore.login({
            email: data.email,
            password: data.password,
          });
        }
      } catch (err) {
        errorLog('createAccountError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingRegisterUser = false;
      }
    }),
    updateUserData: flow(function* (data) {
      const { artistStore } = getRoot<IGlobalStore>(self);
      log('updateUserData >>>>', data);
      try {
        self.flags.isFetchingUpdatingUserData = true;
        const response = yield axios.patch(endpoints.updateUser, data);
        log('updateUserDataResponse >>>>', response);
        if (response.data.success) {
          yield self.getUserData({ silent: true });
          yield artistStore.getArtistData(self.userData.artistId);
        }
      } catch (err) {
        errorLog('updateUserDataError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdatingUserData = false;
      }
    }),
    requestChangePasswordAuthCode: flow(function* (data) {
      log('requestChangePasswordAuthCode >>>>', data);
      try {
        self.flags.isFetchingRequestingChangePasswordAuthCode = true;
        const response = yield axios.post(endpoints.requestAuthCode, data);
        log('requestChangePasswordAuthCodeResponse >>>>', response);
      } catch (err) {
        errorLog('requestChangePasswordAuthCodeError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingRequestingChangePasswordAuthCode = false;
      }
    }),
    validateChangePasswordAuthCode: flow(function* (data) {
      log('validateChangePasswordAuthCode >>>>', data);
      try {
        self.flags.isFetchingValidatingAuthCode = true;
        const response = yield axios.patch(endpoints.validateAuthCode, data);
        log('validateChangePasswordAuthCodeResponse >>>>', response);
      } catch (err) {
        errorLog('validateChangePasswordAuthCodeError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingValidatingAuthCode = false;
      }
    }),
    changePassword: flow(function* (data) {
      log('changePassword >>>>', data);
      try {
        self.flags.isFetchingChangingPassword = true;
        const response = yield axios.patch(endpoints.changePassword, data);
        log('changePasswordResponse >>>>', response);
      } catch (err) {
        errorLog('changePasswordError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingChangingPassword = false;
      }
    }),
  }));

export interface IUserStore extends Instance<typeof UserStore> {}

export default UserStore;
