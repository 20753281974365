import { types, applySnapshot, getSnapshot, Instance, flow, getRoot, cast } from 'mobx-state-tree';
import axios from 'axios';
import debug from 'debug';

import endpoints from '@/config/endpoints';
import { IGlobalStore } from '@/store';

import Flags from './models/Flags';
import Artist from './models/Artist';

const log = debug('store:artist:log');
const errorLog = debug('store:artist:error');

const ArtistStore = types
  .model({
    artistId: types.maybeNull(types.string),
    artistData: types.optional(Artist, {}),
    flags: types.optional(Flags, {}),
  })
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
      delay: (ms: number) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    };
  })
  .actions((self) => ({
    getArtistData: flow(function* (artistId) {
      log('getArtistData >>>>');
      try {
        self.flags.isFetchingArtistData = true;
        const response = yield axios.get(endpoints.getArtist(artistId));
        log('getArtistDataResponse >>>>', response);
        if (response.data.success) {
          self.artistData = cast(response.data.artist);
          self.artistId = cast(response.data.artistId);
        }
      } catch (err) {
        errorLog('getArtistDataError >>>>', err);
      } finally {
        self.flags.isFetchingArtistData = false;
      }
    }),
  }));

export interface IArtistStore extends Instance<typeof ArtistStore> {}

export default ArtistStore;
