import { useState, createContext, FC, ReactNode } from 'react';
import { v4 as uuid } from 'uuid';

import { IToastContext, IToast } from './types';
import ToastWrapper from '.';

const ToastContext = createContext<IToastContext>({
  addToast: () => {
    //Empty Function
  },
  toasts: [],
});

interface ToastProviderProps {
  children: ReactNode;
}

const ToastProvider: FC<ToastProviderProps> = ({ children }) => {
  const [toasts, setToasts] = useState<IToast[]>([]);

  const removeToast = (idToRemove: string) => {
    setToasts((currentToasts) => currentToasts.filter(({ id }) => id !== idToRemove));
  };

  const onClose = ({ timeout, id }: { timeout: NodeJS.Timeout; id: string }) => {
    clearTimeout(timeout);
    removeToast(id);
  };

  const addToast = (toast: IToast) => {
    setToasts((currentToasts) => {
      const id = toast.id ?? uuid();
      const duration = toast.duration ?? 6000;

      const timeout = setTimeout(() => {
        removeToast(id);
      }, duration + 2000);

      return [
        ...currentToasts,
        {
          ...toast,
          id,
          duration,
          onClose: () => {
            onClose({ timeout, id });
          },
        },
      ];
    });
  };

  return (
    <ToastContext.Provider value={{ addToast, toasts }}>
      {children}
      <ToastWrapper toasts={toasts} />
    </ToastContext.Provider>
  );
};

export { ToastContext, ToastProvider };
