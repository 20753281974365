import usePermissions from './usePermissions';

export interface UseHasActionAccessParams {
  action?: string;
  actions?: string[];
}

export default function useHasActionAccess({
  action = '',
  actions = [],
}: UseHasActionAccessParams): boolean {
  const { accessActions } = usePermissions();
  if (actions?.length > 0) {
    return !!actions.some((a) => accessActions.has(a));
  }
  return accessActions.has(action);
}
