import { Instance, types } from 'mobx-state-tree';

const Pagination = types.model({
  totalRecords: types.maybeNull(types.number),
  totalMatches: types.maybeNull(types.number),
  firstPage: types.maybeNull(types.number),
  lastPage: types.maybeNull(types.number),
  pageSize: types.maybeNull(types.string),
  totalPages: types.maybeNull(types.number),
  page: types.maybeNull(types.string),
});

export interface IPagination extends Instance<typeof Pagination> {}
export default Pagination;
