import React, { FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';

import useStore from '@/hooks/useStore';
import DefaultLayout from './DefaultLayout';
import MainLayout from './MainLayout';

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const { loginStore } = useStore();
  const isAuthenticated = loginStore.flags.isAuthenticated;
  console.log(isAuthenticated);
  if (isAuthenticated) {
    return <MainLayout>{children}</MainLayout>;
  }

  return <DefaultLayout>{children}</DefaultLayout>;
};

export default observer(Layout);
