import { types } from 'mobx-state-tree';

// STORES
import UiStore, { IUi } from './ui';
import LoginStore, { ILoginStore } from './login';
import UserStore, { IUserStore } from './user';
import RoleStore, { IRoleStore } from './role';
import MyContentStore, { IMyContentStore } from './myContent';
import ArtistStore, { IArtistStore } from './artist';
import AdminStore, { IAdminStore } from './admin';

export const GlobalStore = types.model({
  uiStore: UiStore,
  loginStore: LoginStore,
  userStore: UserStore,
  roleStore: RoleStore,
  myContentStore: MyContentStore,
  artistStore: ArtistStore,
  adminStore: AdminStore,
});

export interface IGlobalStore {
  uiStore: IUi;
  loginStore: ILoginStore;
  userStore: IUserStore;
  roleStore: IRoleStore;
  myContentStore: IMyContentStore;
  artistStore: IArtistStore;
  adminStore: IAdminStore;
}

const store = GlobalStore.create({
  uiStore: UiStore.create({}),
  userStore: UserStore.create({}),
  roleStore: RoleStore.create({}),
  loginStore: LoginStore.create({}),
  myContentStore: MyContentStore.create({}),
  artistStore: ArtistStore.create({}),
  adminStore: AdminStore.create({}),
});

export default store;
