import { useCallback } from 'react';
import debug from 'debug';

import useStore from '@/hooks/useStore';

export interface IUseGetUserData {
  loadUserData: () => void;
  isLoadingUserData: boolean;
}

const log = debug('hook:useGetUserData:log');
const error = debug('hook:useGetUserData:error');

function useGetUserData(): IUseGetUserData {
  const { userStore, roleStore, artistStore } = useStore();
  const isLoadingUserData = userStore.flags.isFetchingUserData;

  const loadUserData = useCallback(async () => {
    try {
      await userStore.getUserData({ silent: false });
      await roleStore.getRoleData();
      if (userStore?.userData?.artistId !== null) {
        await artistStore.getArtistData(userStore.userData.artistId);
      }
    } catch {
      // empty catch for now
    }
  }, []);

  return { loadUserData, isLoadingUserData };
}

export default useGetUserData;
