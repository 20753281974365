import { createTheme, ThemeOptions, Theme } from '@material-ui/core/styles';

export const lTheme = {
  // primary
  loudness: '#eabb2a',
  brandGreen: '#49F0AC',
  pine: '#014651',
  // secondary
  ferrari: '#FF5D51',
  water: '#5ED9ED',
  pineapple: '#FFC61F',
  // complementary
  tea: '#DCEEDF',
  opal: '#026E5E',
  peacock: '#014651',
  arctic: '#C2EBF2',
  cerulean: '#4367E2',
  navy: '#1B2C77',
  bottle: '#203C4D',
  vanilla: '#EFF3A1',
  mellow: '#E8D87D',
  cheddar: '#DF9A00',
  juice: '#F38A1B',
  chocolate: '#332E24',
  ballet: '#F2CECB',
  salmon: '#FA9E9F',
  carmine: '#992922',
  merlot: '#EB001B',
  // gray scale
  cotton: '#FFFFFF',
  pearl: '#FAFAFA',
  stone: '#EBEEF2',
  dolphin: '#BFC5CC',
  shark: '#8F9499',
  extraGrey: '#4C545B',
  charcoal: '#303133',
  obsidian: '#000000',
  crow: '#1C1C1E',
  panter: '#292929',
  ink: 'rgba(18, 18, 18, 0.9)',
  oil: '#121212',
  // expenses
  ocher: '#D58807',
  mango: '#FEBB1A',
  honey: '#E2D16A',
  lime: '#EBF390',
  ruby: '#FC4440',
  crimson: '#851A1A',
  coral: '#F68A8E',
  rosewood: '#EEC2BF',
  wine: '#960042',
  rose: '#D4005B',
  barbie: '#FB1582',
  bubblegum: '#DB6AA0',
  eggplant: '#5B0B6A',
  jamisne: '#8900A0',
  magenta: '#C327DE',
  orchid: '#B36CC0',
  midnight: '#151D63',
  iris: '#344DDB',
  sky: '#51D1E8',
  aqua: '#B6E7EF',
  forest: '#073640',
  emerald: '#0D5C4B',
  jade: '#31997C',
  mint: '#25D19E',
  spring: '#42F19C',
  apple: '#A5D0AB',
  darkLoudness: '#0e0c21',
  midnightLoudness: '#19163c',
  purpleLoudness: '#7674a4',
  darkPurpleLoudness: '#282363',
};

declare module '@material-ui/core/styles' {
  interface Theme {
    lTheme: typeof lTheme;
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    lTheme?: typeof lTheme;
  }
}

const baseTheme: Partial<ThemeOptions> = {
  lTheme,
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 100,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 4px 20px rgba(1, 70, 81, 0.08)',
      },
    },
    MuiOutlinedInput: {
      inputAdornedStart: {
        paddingLeft: 5,
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: lTheme.bottle,
      contrastText: lTheme.cotton,
    },
    secondary: {
      main: lTheme.mint,
      light: lTheme.cotton,
      contrastText: lTheme.dolphin,
    },
    background: {
      paper: lTheme.cotton,
      default: lTheme.pearl,
      // dark: oysterTheme.chocolate,
    },
    success: {
      main: lTheme.apple,
    },
    error: {
      main: lTheme.ferrari,
      light: lTheme.salmon,
      dark: lTheme.merlot,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ['Montserrat', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: 14,
    button: {
      textTransform: 'none',
    },
    h2: {
      fontSize: 40,
      fontWeight: 'bold',
      margin: 0,
      color: lTheme.charcoal,
    },
    h3: {
      fontSize: 20,
      fontWeight: 'bold',
      margin: 0,
      color: lTheme.charcoal,
    },
    h4: {
      fontSize: 16,
      fontWeight: 'normal',
      margin: 0,
      color: lTheme.charcoal,
    },
    h5: {
      fontSize: 14,
      fontWeight: 600,
      margin: 0,
      color: lTheme.obsidian,
    },
    h6: {
      fontSize: 12,
      fontWeight: 'normal',
      margin: 0,
      color: lTheme.charcoal,
    },
  },
};

const theme = createTheme(baseTheme);

export type ILTheme = keyof Theme['lTheme'];
export default theme;
