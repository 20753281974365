const allowedActions = {
  HUB_ACCESS: 'hub_access',
  MY_CONTENT_ACCESS: 'my_content_access',
  ADMIN_ACCESS: 'admin_access',
  CREATE_MEDIA: 'create_media',
  ADD_MEDIA_FILE: 'add_media_file',
  REMOVE_MEDIA_FILE: 'remove_media_file',
  EDIT_MEDIA_FILE: 'edit_media_file',
  EDIT_MEDIA: 'edit_media',
  REMOVE_MEDIA: 'remove_media',
  PROFILE_ACCESS: 'profile_access',
  administration_access: 'administration_access',
  MEDIA_ADMIN: 'media_admin',
  APPROVE_MEDIA_FILE: 'approve_media_file',
} as const;

export { allowedActions };
