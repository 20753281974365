export default {
  login: '/login',
  getUser: '/user',
  createAccount: '/user/create-account',
  getRole: '/role',
  getMyMediaList: (artistId: string): string =>
    `/media/list?artistId=${artistId}&pageSize=10000&sortOrder=desc&sortField=media_id`,
  getMediaInfo: (mediaId: string): string => `/media/${mediaId}`,
  createMedia: '/media',
  updateMedia: '/media',
  createMediaFile: '/media-files',
  updateMediaFile: '/media-files',
  deleteMedia: (mediaId: string): string => `/media/${mediaId}`,
  deleteMediaFile: (mediaFileId: string): string => `/media-files/${mediaFileId}`,
  getArtist: (artistId: string): string => `/artists/${artistId}`,
  updateUser: '/user',
  requestAuthCode: '/auth-codes',
  validateAuthCode: '/auth-codes/validate',
  changePassword: '/user/password',
  getMediaFilesList: '/media-files/list?pageSize=200000000&sortField=valid&sortOrder=asc',
  approveMediaFile: '/media-files/approve',
  rejectMediaFile: '/media-files/reject',
};
