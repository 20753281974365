import { applySnapshot, types } from 'mobx-state-tree';

const Flags = types
  .model({
    isFetchingUserData: false,
    isFetchingRegisterUser: false,
    isFetchingUpdatingUserData: false,
    isFetchingRequestingChangePasswordAuthCode: false,
    isFetchingValidatingAuthCode: false,
    isFetchingChangingPassword: false,
  })
  .actions((self) => ({
    updateFlag: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }));

export default Flags;
