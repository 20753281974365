import React, { FC, useState, useEffect, SVGProps, ReactNode } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';

import { IoMusicalNotes, IoCog, IoLogOut } from 'react-icons/io5';

import useHasActionAccess from '@/libraries/accessManager/access/useHasActionAccess';
import { allowedActions } from '@/libraries/accessManager/constants/allowedActions';
import { allowedRolesDisplayEs } from '@/libraries/accessManager/constants/allowedRoles';
import useStore from '@/hooks/useStore';
import useLogout from '@/hooks/useLogout';
import isURL from '@/utils/isURL';

import { ROUTES } from '@/constants/routes';

import SidebarNavigation from '@/components/SidebarNavigation';
import SidebarUserFooter from '@/components/SidebarUserFooter';
import WebDashboardLayout from './webDashboardLayout';

const URL_FIRST_PART = /^\/[\w-]*/;

interface IconProps extends SVGProps<SVGSVGElement> {
  defaultColor?: boolean;
}

interface ISidebarItem {
  title: string;
  disabled: boolean;
  icon: FC<IconProps>;
  link: string;
  tooltip?: string;
  itemId?: string;
}

interface MainLayoutProps {
  children: ReactNode;
}

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  const { userStore, roleStore } = useStore();
  const { pathname } = useLocation();
  const { logoutUser } = useLogout();
  const history = useHistory();

  const [sidebarItems, setSidebarItems] = useState<ISidebarItem[]>([]);
  const [currentRoute] = pathname?.match(URL_FIRST_PART) || ['/'];

  const hasMyContentAccess = useHasActionAccess({ action: allowedActions.MY_CONTENT_ACCESS });
  const hasAdminAccess = useHasActionAccess({ action: allowedActions.ADMIN_ACCESS });

  const onSidebarClick = (link: string) => {
    if (!isURL(link)) {
      history.push(link);
    } else {
      window.location.href = link;
    }
  };

  const role = roleStore.role as keyof typeof allowedRolesDisplayEs;

  useEffect(() => {
    const items = [] as ISidebarItem[];

    if (hasMyContentAccess) {
      items.push({
        title: 'Mi contenido',
        disabled: false,
        icon: IoMusicalNotes,
        link: ROUTES.MY_CONTENT,
      });
    }

    if (hasAdminAccess) {
      items.push({
        title: 'Administración',
        disabled: false,
        icon: IoCog,
        link: ROUTES.ADMINISTRATION,
      });
    }

    setSidebarItems(items);
  }, [pathname, hasMyContentAccess, hasAdminAccess]);

  return (
    <WebDashboardLayout
      sidebarMenu={
        <SidebarNavigation items={sidebarItems} selected={currentRoute} onChange={onSidebarClick} />
      }
      leftHeaderComp={
        <IoLogOut style={{ color: '#FFF', cursor: 'pointer' }} onClick={logoutUser} />
      }
      footerComp={
        <SidebarUserFooter
          logout={logoutUser}
          fullName={userStore.userData?.userFirstName}
          accountType={allowedRolesDisplayEs[role]}
          logoutStr="Cerrar Sesión"
          goToProfile={() => history.push(ROUTES.PROFILE)}
        />
      }
    >
      {children}
    </WebDashboardLayout>
  );
};

export default observer(MainLayout);
