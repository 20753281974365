import { FC, ReactNode } from 'react';

import useHasActionAccess from '@/libraries/accessManager/access/useHasActionAccess';
import RedirectTo from '@/libraries/accessManager/access/redirectTo';

export interface CreditAuthProps {
  children: ReactNode;
  allowedAction: string;
  redirectToPath?: string;
}

const AuthRoute: FC<CreditAuthProps> = ({ children, allowedAction, redirectToPath = '/' }) => {
  const accessRoute = useHasActionAccess({ action: allowedAction });
  if (accessRoute) {
    return <>{children}</>;
  }
  return <RedirectTo pathName={redirectToPath} />;
};

export default AuthRoute;
