import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    modalWrapper: {
      margin: 'auto',
      position: 'relative',
      top: '50%',
      transform: 'translate(0, -50%)',
    },
  }),
);

export default useStyles;
