import { Instance, types } from 'mobx-state-tree';

const User = types.model({
  userId: types.maybeNull(types.number),
  artistId: types.maybeNull(types.number),
  userName: types.maybeNull(types.string),
  userFirstName: types.maybeNull(types.string),
  userMiddleName: types.maybeNull(types.string),
  userLastName: types.maybeNull(types.string),
  userFullName: types.maybeNull(types.string),
  userPhone: types.maybeNull(types.string),
  userEmail: types.maybeNull(types.string),
  isActive: types.optional(types.boolean, false),
});

export interface IUser extends Instance<typeof User> {}
export default User;
