import { applySnapshot, types } from 'mobx-state-tree';

const Flags = types
  .model({
    isFetchingMediaFilesList: false,
    isFetchingApprovingMediaFile: false,
    isFetchingRejectingMediaFile: false,
  })
  .actions((self) => ({
    updateFlag: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }));

export default Flags;
