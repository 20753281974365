import React, { FC } from 'react';
import Backdrop from '@material-ui/core/Backdrop';

import Lottie from 'lottie-react';

import loaderAnimation from '@/assets/lotties/loading.json';
import useStyles from './styles';

interface CustomBackdropProps {
  loading: boolean;
}

const CustomBackdrop: FC<CustomBackdropProps> = ({ loading }) => {
  const css = useStyles();
  return (
    <Backdrop className={css.backdrop} open={loading}>
      {loading && <Lottie className={css.spinner} animationData={loaderAnimation} />}
    </Backdrop>
  );
};

export default CustomBackdrop;
