import { FC } from 'react';
import Toast from '../Toast';
import { IToastWrapper, IToast } from './types';
import useStyles from './styles';

const ToastWrapper: FC<IToastWrapper> = ({ toasts }) => {
  const cls = useStyles();

  return (
    <div className={cls.ToastContainer}>
      {toasts.map((toast: IToast) => (
        <Toast
          key={toast.id}
          type={toast.type}
          title={toast.title}
          message={toast.message}
          duration={toast.duration}
          horizontal={toast.horizontal}
          vertical={toast.vertical}
          onClose={toast.onClose}
          customIcon={toast.customIcon}
        />
      ))}
    </div>
  );
};

export default ToastWrapper;
