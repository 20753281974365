import axios from 'axios';

import { AuthCookie } from '@/utils/authCookie';
import { ROUTES } from '@/constants/routes';

import { baseUrl } from './variables';

const axiosConfig = async () => {
  axios.defaults.baseURL = baseUrl;
  axios.interceptors.request.use((config) => {
    config.headers.Accept = 'application/json';
    config.headers['Authorization'] = AuthCookie.getCookie();
    config.headers['Content-Type'] = 'application/json';
    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      const { error, code } = err.response.data;
      const noAuthMsg = error === 'No autorizado';
      const isNoAuth = noAuthMsg || code === 500 || code === 403;

      if (isNoAuth) {
        window.location.href = ROUTES.LOGIN;
      }

      return Promise.reject(err.response.data);
    },
  );
};

export default axiosConfig;
