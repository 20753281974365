import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => {
  return createStyles({
    paper: {
      width: '100vw',
      boxShadow: 'none',
      [theme.breakpoints.up('sm')]: {
        width: '80vw',
      },
      [theme.breakpoints.up('md')]: {
        width: '75vw',
      },
      [theme.breakpoints.up('lg')]: {
        maxWidth: '50vw',
      },
    },
    defaultWidthPaper: {
      width: '100%',
      maxWidth: '440px',
    },
  });
};

export default makeStyles(styles);
